<template>
  <div>
    <!-- top -->
    <div style="color: #777776; height: 5mm;position: absolute;margin-top: -5mm;width: 100%" v-if="drawColorNumbers">
      <div :style="'text-align:center; font-size:3.8mm;display: inline-block;width: '+ brickSize + `;opacity: ` +(number % 2 == 0 ? 100: 0 )" v-for="number in project.meta.plate_size">
        {{ number }}
      </div>
    </div>
    <!-- left -->
    <div style="color: #777776;top:-1mm;width: 5mm;position: absolute;left: -5mm;height: 100%" v-if="drawColorNumbers">
      <div :style="'text-align:center; font-size:3.8mm;display: block;height: '+ brickSize" v-for="number in project.meta.plate_size">
        {{ number }}
      </div>
    </div>
    <!-- bottom -->
    <div style="color: #777776; height: 5mm;position: absolute;bottom: -5mm; width: 100%" v-if="drawColorNumbers">
      <div
        :style="'text-align:center; font-size:3.8mm;display: inline-block;width: '+ brickSize + `;opacity: ` +(number % 2 == 0 ? 100: 0 )"
        v-for="number in project.meta.plate_size">
        {{ number }}
      </div>
    </div>
    <!-- right -->
    <div style="color: #777776;top:-1mm;width: 5mm;position: absolute;right: -5mm;height: 100%" v-if="drawColorNumbers">
      <div :style="'text-align:center; font-size:3.8mm;display: block;height: '+ brickSize" v-for="number in project.meta.plate_size">
        {{ number }}
      </div>
    </div>
    <div ref="canvas" style="line-height: 0;">
      <svg :width="brickSize" :height="brickSize" viewBox="0 0 25 25" version="1.1"
           xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink" v-for="colorItem in colorsData"
           style="display: inline-block;line-height: 0;vertical-align: middle;">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Artboard" :fill="'#' + colorItem.hex" v-html="colorItem.svg">
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>

export default {
  name: "PlateMap",
  data() {
    return {
      brickSize: '5mm',
      layer: null,
      colorsData: [],
      colorsMap: {},
    }
  },
  props: ['project', 'index', 'colors', 'drawColorNumbers'],
  computed: {
    // ...mapGetters(['availableColors'])
  },
  methods: {},
  mounted() {
    for (let item of this.colors) {
      this.colorsMap[item.id] = item;
    }

    const x = this.index % this.project.meta.canvas_width
    const y = Math.floor(this.index / this.project.meta.canvas_width)
    // const y = (this.index % this.project.meta.canvas_height)
    // const x = Math.round(this.index / this.project.meta.canvas_width)


    let startX = x * this.project.meta.plate_size
    let endX = startX + this.project.meta.plate_size
    let startY = y * this.project.meta.plate_size
    let endY = startY + this.project.meta.plate_size

    const colors = []

    for (let y = startY; y < endY; y++) {
      for (let x = startX; x < endX; x++) {
        let fetchIndex = y * this.project.meta.plate_size * this.project.meta.canvas_width + x
        // console.log(fetchIndex)

        var lastColorId = 0
        for (let layer of this.project.layers) {
          if (layer.data[fetchIndex] > 0) {
            lastColorId = layer.data[fetchIndex]
          }
        }
        colors.push(this.colorsMap[lastColorId])
      }
    }


    this.colorsData = colors
    // console.log(this.colorsData)
    let widthInMM = this.$refs.canvas.clientWidth * 0.264583
    let mmPerPixel = widthInMM / this.project.meta.plate_size
    // console.log('widthInMM', widthInMM, 'mmPerPixel', mmPerPixel)
    this.brickSize = mmPerPixel + 'mm'
  }
}
</script>

<style scoped>

</style>
